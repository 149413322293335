import Link from 'next/link';
import { useRouter } from 'next/router';
const IS_PROD = (process?.env?.NEXT_PUBLIC_IN_DEV === 'false' && process?.env?.NEXT_PUBLIC_GOOGLE_TAG_MANAGER) ? true : false;

type LinkerProps = React.PropsWithChildren<{
  url: string;
  title?: string;
  ariaLabel?: string;
  classes?: string;
}>;
export function Linker({ url, classes, title, ariaLabel, children }: LinkerProps) {
  const router = useRouter();
  // console.log(router.asPath)
  // console.log(router.pathname, url)
  // check if URL starts with tel:

  // create a function to return this: return gtag_report_conversion(${url});
  // then pass it to onClick
  const handleClick = (url: string) => {
    if(IS_PROD){
      return gtag_report_conversion(`${url}`);
    } else {
      return;
    }
  }

  if (!url) {
    return <>{children}</>
  } else if(url.startsWith('tel:')) {
    return <a onClick={() => handleClick(url)}  href={url} target='_blank' rel='noopener noreferrer' className={classes ? classes : ''} title={'call'} aria-label={'call'}>{children}</a>
  } else if (url.includes('https') || url.includes('http')) {
    return <a href={url} target='_blank' rel='noopener noreferrer' className={classes ? classes : ''}>{children}</a>
  } else {
    return <Link href={url}><a className={`${classes ? classes : ''} ${router.asPath === url ? "active" : ""}`} title={title} aria-label={ariaLabel}>{children}</a></Link>
  }
}
function gtag_report_conversion(arg0: string) {
  throw new Error('Function not implemented.');
}

