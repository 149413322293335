import { Logo } from '@/components/layout/logo'
import { Linker } from '@/components/content/linker'
import { DropDown } from '@/components/layout/header/drop-down'
import { MobileNav } from '@/components/layout/header/mobile-nav'
import { useUser } from '@/utils/useUser';
import Link from 'next/link';

import dynamic from 'next/dynamic'
const DynamicComponentWithNoSSR = dynamic(
  () => import('@/components/layout/header/snipcart'),
  { ssr: false }
)

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function TopLevelLink({ link }) {
  // console.log({link})
  if (link?.links?.length >= 1) {
    return <DropDown key={link._key} link={link} />
  } else {
    return <li key={link._key}><Linker url={link.siteLink} classes="text-base font-medium text-gray-500 hover:text-gray-900">{link.title}</Linker></li>
  }
}

export function Header({ siteNav, topNav, siteSettings }) {
  const { title } = siteSettings
  const appLoginEnabled = siteSettings?.appSettings?.appLoginEnabled
  const { isLoading, subscription, userDetails } = useUser();

  return (
    <div className="relative">
      <div className="mt-2 md:mt-4 inner-wrapper">
        <nav className='justify-end hidden w-full lg:flex'>
          <ul className="flex space-x-4">
            {(topNav && topNav.length >= 1) &&
              topNav?.map(link =>
                <li key={link._key}><Linker url={link?.siteLink} classes='text-sm font-medium text-gray-500 hover:text-gray-900'>{link.title}</Linker></li>)
            }

            {/* SUPABASE LINKS */}
            {(process?.env?.NEXT_PUBLIC_SUPABASE_URL && process?.env?.NEXT_PUBLIC_SUPABASE_KEY && appLoginEnabled === true) && <>

              {(!isLoading && userDetails) && (<>
                <li className={`${(topNav && topNav.length >= 1) && 'pl-4 ml-2 border-l border-slate-200'}`}><Link href='/app/dashboard'><a className='px-2 py-1 text-sm font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm whitespace-nowrap hover:bg-indigo-700'>Dashboard</a></Link></li>

                <li><Linker url='/api/auth/logout' classes='text-center px-2 py-1 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm'>Logout</Linker></li>
              </>)}

              {(!isLoading && !userDetails) && (<>
                <li className={`${(topNav && topNav.length >= 1) && 'pl-4 ml-2 border-l border-slate-200'}`}><Link href='/app/login'><a className='px-2 py-1 text-sm font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm whitespace-nowrap hover:bg-indigo-700'>Login</a></Link></li>

                <li><Linker url='/app/join' classes='text-center px-2 py-1 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm'>Join</Linker></li>
              </>)}


            </>}
            {/* SNIPCART CART */}
            {process?.env?.NEXT_PUBLIC_SNIPCART_PUB_API_KEY &&
              <li className='flex items-center space-x-1 text-lg text-gray-600'>
                <DynamicComponentWithNoSSR />
              </li>}
          </ul>
        </nav>
        <div className="flex flex-wrap items-center justify-between mb-3 md:mb-4 lg:space-x-10">

          <div className={`flex justify-start lg:w-0 lg:flex-1 ${(topNav && topNav.length >= 1) ? 'lg:-mt-[27px]' : ''}`}>
            <Linker url="/">
              {title && <span className="sr-only">{title}</span>}
              <Logo classes="w-auto h-12 md:h-16 lg:h-24" alt="Logo" mode="light" />
            </Linker>
          </div>
          <nav className="hidden lg:flex">
            <ul className="flex space-x-10">
              {siteNav.map(link => <TopLevelLink key={link._key} link={link} />)}
            </ul>
          </nav>

          <MobileNav siteNav={siteNav} topNav={topNav} appLoginEnabled={appLoginEnabled || false} />
        </div>
      </div>
    </div>
  )
}
