import { SocialLinks } from '@/components/layout/footer/social-links'
import { BottomFooterNav } from '@/components/layout/footer/bottom-footer-nav'
import { Linker } from '@/components/content/linker'
import { toggle24hr } from '@/utils/helpers';
import { HiLocationMarker, HiMail } from "react-icons/hi";

const SubLinks = ({ subLinks }) => {
  return (
    <div>
      <h3 className="text-sm font-semibold tracking-tight text-gray-200 uppercase">{subLinks?.title}</h3>
      <ul className="mt-4">
        {subLinks?.links?.map(link => <li className="mb-2 text-base text-gray-100" key={link._key}>
          <Linker url={link.siteLink} classes="hover:underline">{link.title}</Linker></li>)}
      </ul>
    </div>
  )
}

export function Footer({ topFooterNav, bottomFooterNav, siteSettings }) {
  const { socialLinks, organizationDetails } = siteSettings;
  let gridCount = [];
  const hasContact = organizationDetails?.address || organizationDetails?.phoneNumber ? true : false;
  const hasHours = (organizationDetails?.operationHours && organizationDetails?.operationHours?.length >= 1) ? true : false;
  const hasSubLinks = topFooterNav?.some(vendor => vendor['links']?.length >= 1);
  const subLinkArray = topFooterNav?.map(vendor => vendor['links']?.length >= 1).filter(val => val === true) || [];
  gridCount = [hasContact, hasHours, ...subLinkArray];
  gridCount = gridCount.filter((value) => value).length;

  return (
    <footer className="text-gray-100 bg-brand-2-700 body-font">
      <
        div className="py-10 mx-auto inner-wrapper">
        <div className={`grid gap-6 md:grid-cols-${gridCount >= 3 ? 2 : 1} md:gap-10 lg:gap-8 lg:grid-cols-${gridCount}`}>

          {hasContact &&
            <div>
              <h3 className="text-sm font-semibold tracking-tight text-gray-200 uppercase">Contact</h3>
              <div className="mt-4">
                {organizationDetails?.address && <p className="mb-2 text-base text-gray-100">{organizationDetails?.address}</p>}

                {(organizationDetails?.city || organizationDetails?.state || organizationDetails?.zip) &&
                  <p className="mb-2 text-base text-gray-100">{organizationDetails?.city} {organizationDetails?.state} {organizationDetails?.zip}</p>}

                {(organizationDetails?.directionsMapURL) &&
                  <p className="flex items-center mb-2 space-x-2 text-base text-gray-100"><HiLocationMarker /> <Linker url={organizationDetails?.directionsMapURL} classes="hover:underline">Map and Directions</Linker></p>}

                <p className="flex items-center mb-2 space-x-2 text-base text-gray-100">
                  <HiMail /> <Linker url='/contact' classes="hover:underline">Email Us</Linker>
                </p>


                {organizationDetails?.phoneNumber && <p className="mb-2 text-base text-gray-100">
                  T: <Linker url={`tel:${organizationDetails?.phoneNumber}`} classes="hover:underline">{organizationDetails?.phoneNumber}</Linker>
                </p>}

                {organizationDetails?.faxNumber && <p className="mb-2 text-base text-gray-100">
                  F: <Linker url={`tel:${organizationDetails?.faxNumber}`} classes="hover:underline">{organizationDetails?.faxNumber}</Linker>
                </p>}

              </div>
            </div>}

          {hasHours &&
            <div>
              <h3 className="text-sm font-semibold tracking-tight text-gray-200 uppercase">Hours</h3>
              <ul className="table w-full mt-4">
                {organizationDetails?.operationHours?.map(time =>
                  <li className="table-row text-base text-gray-100" key={time._key}><div className="table-cell pb-1">{time.day}</div> <div className="table-cell pb-1">{toggle24hr(time?.opensAt, 0)} - {toggle24hr(time?.closesAt, 0)}</div></li>
                )}
              </ul>
            </div>}

          {hasSubLinks &&
            topFooterNav?.map(subLinks => (subLinks?.links && subLinks.links.length >= 1) && <SubLinks subLinks={subLinks} key={subLinks._key} />)
          }

        </div>
      </div>

      <div className="bg-brand-2-800">
        <div className="flex flex-col items-center px-5 py-6 mx-auto inner-wrapper sm:flex-row">

          <p className="mt-4 ml-4 text-xs text-gray-200 md:text-sm sm:mt-0">&copy; {new Date().getFullYear()} {siteSettings?.title} - All Rights Reserved.
          </p>
          {bottomFooterNav && <BottomFooterNav bottomFooterNav={bottomFooterNav} />}
          <span className="grid justify-center grid-flow-col gap-2 auto-cols-max sm:ml-auto sm:mt-0">
            {socialLinks && <SocialLinks socialLinks={socialLinks} />}
          </span>
        </div>
      </div>
    </footer>
  )
}
